import { createContext } from "preact";

export enum Language {
    SWEDISH = 0,
    ENGLISH = 1
}

export const LanguageToShort = (language:Language) => {
    switch(language){
        case Language.SWEDISH:
            return 'se';
        case Language.ENGLISH:
            return 'en';
        default: return 'en';
    }
}

export interface TemplateProps {
    templateClass?:string;
}

export interface APIEmailResponse {
    success:boolean;
}

export const Theme = createContext('');