import { h, Component } from 'preact';
import { Route, Router } from 'preact-router';

import Home from '../routes/index';
import NotFoundPage from '../routes/notfound';
import { Language } from '../shared/types';

class App extends Component {
    constructor(){
        super();
    }

    public render = () => {
        return (
            <div id="preact_root">
                <Router>
                    <Route path="/" component={Home} section={'home'} language={Language.SWEDISH} />
                    <Route path="/se" component={Home} section={'home'} language={Language.SWEDISH} />
                    <Route path="/se/home" component={Home} section={'home'} language={Language.SWEDISH} />
                    <Route path="/se/portfolio" component={Home} section={'portfolio'} language={Language.SWEDISH} />
                    <Route path="/se/about" component={Home} section={'about'} language={Language.SWEDISH} />
                    <Route path="/se/contact" component={Home} section={'contact'} language={Language.SWEDISH} />
                    <Route path="/en" component={Home} section={'home'} language={Language.ENGLISH} />
                    <Route path="/en/home" component={Home} section={'home'} language={Language.ENGLISH} />
                    <Route path="/en/portfolio" component={Home} section={'portfolio'} language={Language.ENGLISH} />
                    <Route path="/en/about" component={Home} section={'about'} language={Language.ENGLISH} />
                    <Route path="/en/contact" component={Home} section={'contact'} language={Language.ENGLISH} />
                    <NotFoundPage default />
                </Router>
            </div>
        );
    }
}

export default App;
